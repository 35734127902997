// import { useMemo, useState } from "react";

// const ElectionLive = ({ electionLiveData = null, firstActive = "" }) => {
//   const [selectedVideo, setSelectedVideo] = useState(firstActive);

//   const liveKeys = useMemo(() => {
//     let temp = [];
//     Object?.keys(electionLiveData).forEach((key) => {
//       if (electionLiveData[key]?.status === "1") {
//         temp.push(key);
//       }
//     });
//     return temp;
//   }, []);
//   return (
//     <div className="flex p-2 bg-white w-auto custom-z" style={{position:"static"}}>
//       <div className="flex flex-col">
//         <div style={{zIndex:10}}>
//           {liveKeys && liveKeys?.length>0 &&
//             liveKeys?.map((liveKey) => (
//               <button
//                 key={liveKey}
//                 className={`px-4 py-2 text-sm font-semibold rounded-t-md ${
//                   liveKey === selectedVideo
//                     ? "bg-primary text-white shadow border-r-0 border-2 border-primary"
//                     : "border-gray-200 border-2"
//                 }`}
//                 id={liveKey}
//                 onClick={() => setSelectedVideo(liveKey)}
//               >
//                 {electionLiveData?.[liveKey].title}
//               </button>
//             ))}
//         </div>
//         {selectedVideo && (
//           <div
//             className="title custom-iframe aspect-w-16 aspect-h-9"
//             dangerouslySetInnerHTML={{
//               __html: `${electionLiveData?.[selectedVideo]?.livetv_code}`,
//             }}
//           />
//         )}
//       </div>
//     </div>
//   );
// };
// export default ElectionLive;

// -----------------------------------------------
import { useEffect, useMemo, useState } from "react";

import LiveShowFullCard from "./LiveTv";
import { useWindowSize } from "@/hooks/useIsMobile";

import { useRouter } from "next/router";

const ElectionLive = ({ electionLiveData = "", firstActive = "" }) => {
  const [selectedVideo, setSelectedVideo] = useState(firstActive);
  const [liveStreamData, setLiveStreamData] = useState(null);

  const [activeTab, setActiveTab] = useState(0);

  const liveKeys = useMemo(() => {
    let temp = [];
    Object?.keys(electionLiveData).forEach((key) => {
      if (electionLiveData[key]?.status === "1") {
        temp.push(key);
      }
    });
    return temp;
  }, [electionLiveData]);

  const vimeoId = process.env.NEXT_PUBLIC_LIVE_TV_VIMEO_ID || 4811929;

  useEffect(() => {
    const fetchLiveVideoData = async () => {
      const apiUrl =
        process.env.NEXT_PUBLIC_LIVE_TV_VIMEO_API + "/event_detail";

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_LIVE_TV_VIMEO_TOKEN}`, // Replace with a real token
          },
          body: JSON.stringify({
            event_id: vimeoId,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch video details");
        }

        const data = await response.json();
        if (data?.data?.Event?.length > 0) {
          setLiveStreamData(data?.data?.Event[0]);
        }
      } catch (err) {
        console.error("Error fetching live video data:", err);
      }
    };

    fetchLiveVideoData();
  }, [vimeoId]);

  // Merge liveKeys with the liveStreamData event title
  const tabs = liveStreamData
    ? [...liveKeys, liveStreamData?.event_title]
    : liveKeys;
  const size = useWindowSize();
  useEffect(() => {
    if (!selectedVideo && tabs?.length > 0) {
      setSelectedVideo(tabs[0]); // Default to the first available tab
      setActiveTab(0); // Set the first tab as active
    }
  }, [tabs, selectedVideo]);
  const router = useRouter();
  const isHomePage = router?.asPath === "/";
  return (
    isHomePage && (
      <div
        className="flex p-2 bg-white w-auto custom-z"
        style={{ position: "static" }}
      >
        <div className="flex flex-col w-full">
          {/* Tab Buttons */}
          <div className="flex ">
            {tabs?.length > 0 &&
              tabs.map((liveKey, index) => (
                <button
                  key={index}
                  className={`px-4 py-2 text-sm font-semibold rounded-t-md transition-all duration-300 ${
                    liveKey === selectedVideo
                      ? "bg-primary text-white shadow border-r-0 border-2 border-primary"
                      : "border-gray-200 border-2 hover:bg-gray-200"
                  }`}
                  onClick={() => {
                    setSelectedVideo(liveKey);
                    setActiveTab(index); // Set active tab based on the button clicked
                  }}
                >
                  {String(liveKey)?.charAt(0)?.toUpperCase() +
                    String(liveKey)?.slice(1)}
                  {/* <div className="flex flex-col">
                  <span>{liveKey}</span>  
                </div> */}
                </button>
              ))}
          </div>

          {/* Tab Content */}
          <div className="w-full">
            {/* Render electionLiveData content if selectedVideo is from electionLiveData */}
            {selectedVideo &&
              electionLiveData?.[selectedVideo]?.livetv_code && (
                <div
                  className="title custom-iframe aspect-w-16 aspect-h-9"
                  dangerouslySetInnerHTML={{
                    __html: `${electionLiveData?.[selectedVideo]?.livetv_code}`,
                  }}
                />
              )}

            {/* Render JWPlayer for live stream */}
            {activeTab === tabs.length - 1 &&
              liveStreamData?.live_stream_url && (
                <div
                  className="title custom-iframe aspect-w-16 aspect-h-9"
                  style={{
                    width:
                      size.width >= 768 && size.width <= 1023 ? "75%" : ` `,
                  }}
                >
                  <LiveShowFullCard vimeoId={vimeoId} />
                </div>
              )}
          </div>
        </div>
      </div>
    )
  );
};

export default ElectionLive;
