import Head from "next/head";

import { useEffect, useRef } from "react";
import { useWindowSize } from "@/hooks/useIsMobile";
import useIntersectionObserver from "@/hooks/intersectionObserver";
import { useRouter } from "next/router";

const LiveShowFullCard = ({
  liveShowData,

  vimeoId,
}) => {
  console.log("liveScheduledData", liveShowData);
  const router = useRouter();
  const size = useWindowSize();
  const divRef = useRef();
  const { isIntersecting, isFullScreened } = useIntersectionObserver(divRef, {
    root: null, // Use the viewport as the container
    rootMargin: "0px",
    threshold: 1, // 10% of the div should be visible
  });

  const isVisible = isIntersecting && router.pathname === "/";
  const shareUrl = encodeURIComponent("https://www.newstak.in?jwsource=fb");
  const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
  var liveTvInstance;
  const initPlayer = () => {
    const athdParam = {
      default_Player: "",
      gap_ac: process.env.NEXT_PUBLIC_GACODE,
      id: "livetv-lt",
      isLive: "true",
      aud_togle: "false",
      noads: "true",
      mute: "true",
      autostart: 1,
      vimeo_url: `${process.env.NEXT_PUBLIC_LIVE_TV_VIMEO_API}/event_hls?event_id=${vimeoId}`,
      vimeo_id:vimeoId,
      controls: false,
      controls: "false",
      controls: 0,
      playbackControls: false,
      playbackControls: "false",
      playbackControls: 0,
      setControls: false,
      setControls: "false",
      setControls: 0,
      title: "NewsTak Live TV",

      fbUrl: fbShareUrl,
      twitr: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        "Newstak Live TV Newscast: Watch free live TV news streaming online and live news updates about breaking news, top headlines, entertainment, sports, world news, and more on Newstak Live TV via @NewstakOfficial"
      )}&url=${encodeURIComponent(
        "https://www.newstak.in"
      )}&original_referer=${encodeURIComponent("https://www.newstak.in")}`,
      email: `mailto:?subject=${encodeURIComponent(
        "Newstak Live TV"
      )}&body=${encodeURIComponent(
        "Newstak Live TV Newscast: Watch free live TV news streaming online and live news updates about breaking news, top headlines, entertainment, sports, world news, and more on Newstak Live TV. \n https://www.newstak.in/"
      )}`,
      share_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}`,
    };
    liveTvInstance = new liveTvEmbed(athdParam, "vidplayer");
    liveTvInstance.play();
  };
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.backgroundColor =
      "transparent";
    setTimeout(() => {
      if (typeof liveTvEmbed !== "undefined") {
        vimeoId && initPlayer();
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const athdParam = {
      default_Player: "",
      gap_ac: "G-DRGFW2213J",
      id: "livetv-lt",
      isLive: "true",
      aud_togle: "false",
      noads: "true",
      mute: "true",
      autostart: 1,
      vimeo_url: `${process.env.NEXT_PUBLIC_LIVE_TV_VIMEO_API}/event_hls?event_id=${vimeoId}`,
      vimeo_id: vimeoId,
      controls: false,
      controls: "false",
      controls: 0,
      playbackControls: false,
      playbackControls: "false",
      playbackControls: 0,
      setControls: false,
      setControls: "false",
      setControls: 0,
      title: "NewsTak Live TV",

      fbUrl: fbShareUrl,
      twitr: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        "Newstak Live TV Newscast: Watch free live TV news streaming online and live news updates about breaking news, top headlines, entertainment, sports, world news, and more on Newstak Live TV via @NewstakOfficial"
      )}&url=${encodeURIComponent(
        "https://www.newstak.in"
      )}&original_referer=${encodeURIComponent("https://www.newstak.in")}`,
      email: `mailto:?subject=${encodeURIComponent(
        "Newstak Live TV"
      )}&body=${encodeURIComponent(
        "Newstak Live TV Newscast: Watch free live TV news streaming online and live news updates about breaking news, top headlines, entertainment, sports, world news, and more on Newstak Live TV. \n https://www.newstak.in/"
      )}`,

      share_link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}`,
    };

    if (!isVisible && !isFullScreened) {
      setTimeout(() => {
        if (typeof liveTvEmbed !== "undefined") {
          const liveTvInstance = new liveTvEmbed(athdParam, "vidplayer");
          liveTvInstance.pause();
        }
      }, 1000);
    }
  }, [isVisible]);

  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          href="https://feeds.intoday.in/livetv/ver-3-0/livetv-alpha.css?ver=16-10-2024"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
        document.addEventListener("DOMContentLoaded", function() {
          document.getElementsByTagName("body")[0].style.backgroundColor = "transparent";
        });
      `,
          }}
        />
        <script
          defer
          src="https://feeds.intoday.in/livetv/ver-3-0/livetv-alpha.js?ver=16-10-2024"
        ></script>
      </Head>

      {size.width > 600 ? (
        <div className="remove-Bg-pd ">
          <div className="live-card-carousel">
            <div className="live-show-left">
              <div
                style={{
                  // width: "35rem",
                  height: "191px",
                  position: "relative",
                }}
              >
                <div ref={divRef} id="vidplayer" class="vidplayer"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="live-card-body">
          <div className="live-card-carousel">
            <div className="live-show-left">
              <div
                ref={divRef}
                id="vidplayer"
                class="vidplayer"
                style={{
                  // width: "25rem",
                  height: "191px",
                  position: "relative",
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LiveShowFullCard;
