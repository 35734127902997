import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const useIntersectionObserver = (elementRef, options = {}) => {
  const router = useRouter();
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isObscured, setIsObscured] = useState(false);
  const [isFullScreened,setFullScreened] = useState(false);

  useEffect(()=>{
    const handleFullscreenChange = (event) =>{
      setFullScreened(!isFullScreened)
    }
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  },[]);

  useEffect(()=>{
     if(isFullScreened){
      setIsIntersecting(true)
     }
  },[isFullScreened])

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
    }, options);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [elementRef, options]);

  useEffect(() => {
    if (isIntersecting && elementRef.current) {
      const checkOcclusion = () => {
        const rect = elementRef.current.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const topElement = document.elementFromPoint(centerX, centerY);

        if (topElement !== elementRef.current && !elementRef.current.contains(topElement)) {
          setIsObscured(true);
        } else {
          setIsObscured(false);
        }
      };

      checkOcclusion();
      window.addEventListener('scroll', checkOcclusion);
      window.addEventListener('resize', checkOcclusion);

      return () => {
        window.removeEventListener('scroll', checkOcclusion);
        window.removeEventListener('resize', checkOcclusion);
      };
    }
  }, [isIntersecting, elementRef]);

  return { isIntersecting, isObscured ,isFullScreened};
};

export default useIntersectionObserver;
